<template>
  <div>
    <!-- Statistik -->
    <Statistic />

    <!-- Pengajuan Surat -->
    <LetterSubmission />

    <!-- Pengaudan Masyarakat -->
    <CitizenReport />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import moment from "moment";
import Statistic from "@/view/components/dashboard/Statistic.vue";
import LetterSubmission from "@/view/components/dashboard/LetterSubmission.vue";
import CitizenReport from "@/view/components/dashboard/CitizenReport.vue";

export default {
  components: {
    Statistic,
    LetterSubmission,
    CitizenReport,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard" },
    ]);
  },
};
</script>
