<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 py-1">
        <div class="card my-statistic-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span class="my-statistic-card-title">Penduduk</span>
              </div>
              <div>
                <span class="my-card-value">{{
                  dashboard_statistic.total_citizen
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 py-1">
        <div class="card my-statistic-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span class="my-statistic-card-title"
                  >Tempat Usaha <br />
                  Aktif</span
                >
              </div>
              <div>
                <span class="my-card-value">{{
                  dashboard_statistic.total_village_bussiness
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 py-1">
        <div class="card my-statistic-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span class="my-statistic-card-title"
                  >Sarana & <br />
                  Prasarana</span
                >
              </div>
              <div>
                <span class="my-card-value">{{
                  dashboard_statistic.total_infrastructure
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 py-1">
        <div class="card my-statistic-card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span class="my-statistic-card-title"
                  >Pengaduan <br />
                  Masyarakat</span
                >
              </div>
              <div>
                <span class="my-card-value">{{
                  dashboard_statistic.total_citizen_report
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
export default {
  data() {
    return {
      dashboard_statistic: {},
    };
  },
  methods: {
    async getDashboardStatistic() {
      this.dashboard_statistic = await module.getDashboardStatistic(
        "dashboard-statistic"
      );
      // If Data Not Found
      if (this.dashboard_statistic == null) {
        // Redirect To Other Page
      } else {
        //
        console.log("dashboard", this.dashboard_statistic);
      }
    },
  },
  mounted() {
    this.getDashboardStatistic();
  },
};
</script>

<style scoped>
.my-statistic-card {
  height: 123px;
  background: #e9fae5;
}

.my-statistic-card-title {
  font-size: 20px;
  font-weight: 500;
}

.my-card-value {
  font-size: 30px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .my-statistic-card {
    height: auto;
  }

  .my-statistic-card-title {
    font-size: 15px;
  }

  .my-card-value {
    font-size: 25px;
    font-weight: 500;
  }
}
</style>