<template>
  <div>
    <!-- Pengajuan Surat -->
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3>Daftar Pengajuan Surat (Berlangsung)</h3>
            <!-- Filter -->
            <div class="row justify-content-between custom-position mt-n3">
              <div class="col filter-periode-letters">
                <button
                  class="btn mx-1 my-1 btn-timeseries"
                  @click="btnStatusActiveOnClick('status_1')"
                  v-bind:class="{
                    'btn-info': btnStatusActive.status_1,
                    'btn-outline-info': btnStatusActive.status_1 == false,
                  }"
                >
                  Menunggu Persetujuan RW
                </button>
                <button
                  class="btn mx-1 my-1 btn-timeseries"
                  @click="btnStatusActiveOnClick('status_2')"
                  v-bind:class="{
                    'btn-info': btnStatusActive.status_2,
                    'btn-outline-info': btnStatusActive.status_2 == false,
                  }"
                >
                  Ditolak RW
                </button>
                <button
                  class="btn mx-1 my-1 btn-timeseries"
                  @click="btnStatusActiveOnClick('status_3')"
                  v-bind:class="{
                    'btn-info': btnStatusActive.status_3,
                    'btn-outline-info': btnStatusActive.status_3 == false,
                  }"
                >
                  Disetujui RW
                </button>
              </div>
              <div class="col-md-8 custom-position-right">
                <button
                  class="btn mx-1 my-1 btn-timeseries"
                  @click="btnActiveOnClick('allPeriode')"
                  v-bind:class="{
                    'btn-info': btnActive.allPeriode,
                    'btn-outline-info': btnActive.allPeriode == false,
                  }"
                >
                  Semua Periode
                </button>
                <button
                  class="btn mx-1 my-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 my-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="pagination" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="pagination"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(range_male)="data">
                <span>{{
                  data.item.start_range_male +
                  " - " +
                  data.item.end_range_male +
                  " " +
                  data.item.uom
                }}</span>
              </template>
              <template #cell(range_female)="data">
                <span>{{
                  data.item.start_range_female +
                  " - " +
                  data.item.end_range_female +
                  " " +
                  data.item.uom
                }}</span>
              </template>

              <template #cell(letter_status_display)="data">
                <b-badge
                  style="cursor: pointer; background: #b5c6ff"
                  class="btn"
                  v-if="data.item.letter_status_id == 1"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
                <b-badge
                  style="cursor: pointer"
                  class="btn btn-danger"
                  v-if="data.item.letter_status_id == 2"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
                <b-badge
                  style="cursor: pointer; background: #088346"
                  class="btn text-white"
                  v-if="data.item.letter_status_id == 3"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
                <b-badge
                  style="cursor: pointer; background: #d863b9"
                  class="btn text-white"
                  v-if="data.item.letter_status_id == 4"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
                <b-badge
                  style="cursor: pointer; background: #95ede2"
                  class="btn"
                  v-if="data.item.letter_status_id == 5"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
                <b-badge
                  style="cursor: pointer;background: rgb(255 102 102);"
                  class="btn text-white"
                  v-if="data.item.letter_status_id == 6"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
                <b-badge
                  style="cursor: pointer;background: rgb(102 255 137);"
                  class="btn"
                  v-if="data.item.letter_status_id == 7"
                  >{{ data.item.letter_status_id_display }}</b-badge
                >
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import moment from "moment";

export default {
  data() {
    return {
      // Filter
      filter: {
        name: "",
        // start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        // end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        start_date: "",
        end_date: "",
        letter_status_id: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        allPeriode: true,
        days: false,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      btnStatusActive: {
        status_1: true,
        status_2: false,
        status_3: false,
      },
      // Pagination
      perPage: 5,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "No.",
          sortable: true,
        },
        {
          key: "registered_date_display",
          label: "Tanggal Pengajuan",
          sortable: true,
        },
        {
          key: "letter_type_name",
          label: "Jenis Surat",
          sortable: true,
        },
        {
          key: "citizen_name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "citizen_id_card_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "letter_status_display",
          label: "Status",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&letter_status_id=${this.filter.letter_status_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "letters",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },
    filterByName() {
      this.pagination();
    },

    // filter
    resetFilter() {
      (this.filter.name = ""),
        (this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"));
      this.filter.end_date = moment()
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      this.pagination();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnStatusActiveOnClick(type) {
      if (type == "status_1") {
        // set filter
        this.filter.letter_status_id = 1;

        // set btn Active
        this.btnStatusActive.status_1 = true;
        this.btnStatusActive.status_2 = false;
        this.btnStatusActive.status_3 = false;
      } else if (type == "status_2") {
        // set filter
        this.filter.letter_status_id = 2;

        // set btn Active
        this.btnStatusActive.status_1 = false;
        this.btnStatusActive.status_2 = true;
        this.btnStatusActive.status_3 = false;
      } else if (type == "status_3") {
        // set filter
        this.filter.letter_status_id = 3;

        // set btn Active
        this.btnStatusActive.status_1 = false;
        this.btnStatusActive.status_2 = false;
        this.btnStatusActive.status_3 = true;
      }

      this.pagination();
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;
        this.btnActive.allPeriode = false;
        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;
        this.btnActive.allPeriode = false;
        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;
        this.btnActive.allPeriode = false;
        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;
        this.btnActive.allPeriode = false;
        // hide period
        this.onShowPeriod = false;
      } else if (type == "allPeriode") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;
        this.btnActive.allPeriode = true;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
        this.btnActive.allPeriode = false;
      }
      this.pagination();
    },
  },
  mounted() {
    this.pagination();
  },
};
</script>

<style scoped>
/* .custom-position{ */
/* text-align: center; */
/* } */

.custom-position-right {
  text-align: right;
}

@media screen and (max-width: 600px) {
  .custom-position {
    text-align: left;
  }

  .custom-position-right {
    text-align: left;
  }
}
</style>